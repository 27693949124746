.preview ul {
    list-style: disc;
}

.preview ul li {
    margin-left: 2em;
}

.preview p {
    margin-bottom: 1em
}